import {ref} from "vue";
import {read, save} from "@/assets/ts/utils";
import {ElMessage} from "element-plus";
import {deckType, getDangerDeck, saveDeck} from "@/assets/ts/deck";

// 玩家事件
export const playerEventList = ref();

// 抽卡模态框
export const deckDialogVisible = ref(false);

// 属性
export const eventFlipList: any = ref([]);
export const eventTitle = ref("当前没有事件");
export const deckListText = ref("");

export const dangerDeckList = ref([]);
const punishCount = ref(0);

// 接受事件
export const acceptEvent = (playerEvent: any, index: number) => {
    runEvent(playerEvent.name);
    playerEventList.value[index].stage = 'active';
};

// 完成事件
export const finishEvent = (playerEvent: any, index: number) => {
    ElMessage({
        message: "已完成事件",
        grouping: true,
        type: 'success',
    });

    deleteEvent(index);
};

// 放弃事件
export const dropEvent = (playerEvent: any, index: number) => {
    punishCount.value += 1;
    dangerDeckList.value = getDangerDeck();
    deckDialogVisible.value = true;
    deleteEvent(index);
};

// 删除事件
const deleteEvent = (index: number) => {
    const gameConfig = read();
    playerEventList.value.splice(index, 1);

    const newEventList: any = ref([]);

    playerEventList.value.forEach((item: any) => {
        if (item) {
            newEventList.value.push(item);
        }
    });

    gameConfig.playerEventList = newEventList.value;
    save(gameConfig);

    if(playerEventList.value.length == 0) {
        eventTitle.value = "当前没有事件";
    }
};

// 个人事件处理机制 ↓
const runEvent = (eventName: any) => {
    const gameConfig = read();

    let str = "";

    // 亚托克斯
    if (eventName == "Aatrox") {
        const gameConfig = read();

        for (const card of gameConfig.deckList[deckType[4]]) {
            if (card.id == "Aatrox") {
                str = "卡牌 - 亚托克斯 - 已存在";
                return;
            }
        }

        const aatroxCard = {
            "id": "Aatrox",
            "type": "StrongDiscomfort",
            "name": "Aatrox",
            "cardName": "亚托克斯",
            "description": "获得一把挽歌且绑定威能位无法更换，可被【贱卖】【重铸】和2阶圣水解除，前二者失去挽歌，后者保留",
            "illustrate": "无",
            "priority": 0,
            "weight": 0,
            "count": 0
        };

        gameConfig.deckList[deckType[4]].push(aatroxCard);
        console.log(gameConfig.deckList[deckType[4]]);
        save(gameConfig);

        str = "已添加卡牌 - 亚托克斯 - 至重度不适中";
    }

    // 顺手牵羊
    if (eventName == "Take-Others") {
        let randomPlayer = Math.round(Math.random() * 5 + 1);

        while (randomPlayer == gameConfig.roleId) {
            randomPlayer = Math.round(Math.random() * 5 + 1);
        }

        str = `抽取 ${randomPlayer} 号玩家的一张卡`;
    }

    // 无中生有
    if (eventName == "Create-Nothing") {
        str = "已为你添加 2 次抽卡机会";
        gameConfig.drawCount += 2;
        save(gameConfig);
    }

    // 窃取
    if (eventName == "Steal") {
        str = "已为你添加 1 次商店免费刷新机会";
        gameConfig.refreshCount += 1;
        save(gameConfig);
    }

    // 扫雷
    if (eventName == "Minesweeper") {
        window.open("https://minesweeper.online/cn/","_blank");
    }

    // 改变事件状态
    for (let i = 0; i < playerEventList.value.length; i++) {
        if (eventName == playerEventList.value[i].name) {
            const gameConfig = read();
            gameConfig.playerEventList[i].stage = "active";
            save(gameConfig);
            break;
        }
    }

    if(str != "") {
        ElMessage({
            message: str,
            showClose: true,
            duration: 0,
        });
    }
};

// 添加卡牌
export const cardFlip = ref(Array.from({length: 12}, () => false));
export const addCard = (card: any, index: number) => {
    if (cardFlip.value[index]) {
        return;
    }

    if (punishCount.value <= 0) {
        ElMessage({
            message: "抽卡次数已用完",
            grouping: true,
            type: 'error',
        });
        return;
    }

    cardFlip.value[index] = true;
    punishCount.value -= 1;

    saveDeck(card);
};

// 初始化
export const initPlayerEvent = () => {
    // 读取游戏存档
    const gameConfig = read();
    playerEventList.value = gameConfig.playerEventList;
    console.log(playerEventList.value);

    eventFlipList.value = [];

    if (playerEventList.value.length != 0) {
        eventTitle.value = "当前有新事件";
        for (const element of playerEventList.value) {
            eventFlipList.value.push(false);
        }

        setTimeout(() => {
            for (let i = 0; i < eventFlipList.value.length; i++) {
                eventFlipList.value[i] = true;
            }
        }, 100);
    } else {
        eventTitle.value = "当前没有事件";
    }
};