import {ref} from "vue";
import {read, save, playerMoney} from "@/assets/ts/utils";
import {ElMessage} from "element-plus";

// 赏金列表
export const bountyList = ref();
export const flipStage = ref(Array.from({length: 3}, () => false));

// 赏金任务完成
export const finishBounty = (index: number) => {
    const gameConfig = read();
    const nullBounty = {
        "id": "V0",
        "type": "Value",
        "name": "",
        "valueName": "当前没有赏金任务",
        "description": "请过段时间再来",
        "weight": 0,
        "count": 1
    }

    flipStage.value[index] = false;
    gameConfig.bountyList[index] = nullBounty;
    gameConfig.money += 3;
    playerMoney.value = gameConfig.money;
    save(gameConfig);

    ElMessage({
        message: "已完成悬赏，获得 3 货币",
        grouping: true,
        type: 'success',
    });
}

//初始化
export const initBounty = () => {
    const gameConfig = read();
    bountyList.value = gameConfig.bountyList;
    console.log(bountyList.value);

    flipStage.value = [];

    setTimeout(() => {
        flipStage.value = Array.from({length: 3}, () => true);
    }, 100);
}