import {ref} from "vue";
import {read, save, shuffle, playerMoney} from "@/assets/ts/utils";
import {ElMessage} from "element-plus";
import {lottery, lotteryByCount} from "@/assets/ts/lottery";
import {deckType, getDeckListTypeCount} from "@/assets/ts/deck";

// 读取游戏信息
export const maps = ref([]);
export const mapName = ref("请选择地图");

// 提示框
export const mapDialogVisible = ref(false);
export const moneyDialogVisible = ref(false);
export const cardDialogVisible = ref(false);

// 输入框
export const moneyInputRef = ref("");
export const cardInputRef = ref("");

// 按钮
export const mapDoorButtonDisabled = ref(false);
export const mapNextButtonDisabled = ref(false);
export const chestNextButtonDisabled = ref(false);
export const flawlessButtonDisabled = ref(false);

// 地图步骤条
// -----------------------------------------------------
// 步骤
export const mapSteps = ref(0);
// 步骤数
export const mapStepNum = ref(0);
// 步骤条每一步长度
export const mapStepWidth = ref(0);

// 隐藏箱进度条
// -----------------------------------------------------
// 步骤
export const chestSteps = ref(0);
// 步骤数
export const chestStepNum = ref(0);
// 步骤条每一步长度
export const chestStepWidth = ref(0);

// 选择地图
export const setMap = (map: any) => {
    const gameConfig = read();

    gameConfig.map = map;
    gameConfig.mapId = map.id;
    gameConfig.level = map.level;
    gameConfig.levelPoint = 1;
    gameConfig.chest = map.chest;
    gameConfig.chestPoint = 1;
    save(gameConfig);

    ElMessage({
        message: '您的游戏地图已被设置为 ' + map.name,
        grouping: true,
        type: 'success',
    });

    mapDialogVisible.value = false;
    setMapInfo();
};

// 遭遇战插旗点
export const mapDoor = () => {
    const gameConfig = read();
    if (gameConfig.map == "") return;

    if (mapStepNum.value > mapSteps.value + 1) return;

    // 赏金任务
    const bountyList = [];
    let bounty;

    // 循环抽取赏金任务
    while (bountyList.length != 3) {
        bounty = lottery(gameConfig.bounty);
        if (checkBounty(bountyList, bounty)) {
            bountyList.push(bounty);
        }
    }

    // 设置赏金任务 与 添加抽卡次数
    gameConfig.bountyList = bountyList;
    gameConfig.drawCount += 2;

    // 刷新商店次数
    if (gameConfig.refreshCount != 1) gameConfig.refreshCount = 1;

    save(gameConfig);

    ElMessage({
        message: '已为你添加了新的赏金任务，2次抽卡机会，1次免费刷新商店机会',
        grouping: true,
        type: 'success',
    });

    // 个人事件
    playerEvent();

    // 全局事件
    if (gameConfig.position == 'captain') {
        globalEvent();
    }

    mapDoorButtonDisabled.value = true;
    setTimeout(() => {
        mapDoorButtonDisabled.value = false;
    }, 3000);
};

// 遭遇战完成按钮
export const mapNext = () => {
    const gameConfig = read();
    if (gameConfig.map == "") return;

    if (mapStepNum.value <= mapSteps.value) {
        mapStepNum.value += 1;

        if (mapStepNum.value == 2) {
            gameConfig.levelPoint = mapStepNum.value;

            save(gameConfig);
            return;
        }

        gameConfig.money += 3;
        gameConfig.levelPoint = mapStepNum.value;

        ElMessage({
            message: '已通关遭遇战获得 3 货币',
            grouping: true,
            type: 'success',
        });

        // 判断是否有免死金牌
        let compensate = true;
        gameConfig.deckList[deckType[2]].forEach((card: any) => {
            if (card.name == "The-Medallion") {
                compensate = false;
            }
        });

        // 负面补偿
        if (compensate) {
            // 负面检测
            const debuffTestCount = ref(0);

            const microDiscomfortListCount = getDeckListTypeCount(gameConfig.deckList[deckType[3]], deckType[3]);
            const strongDiscomfortListCount = getDeckListTypeCount(gameConfig.deckList[deckType[4]], deckType[4]);
            const unacceptableListCount = getDeckListTypeCount(gameConfig.deckList[deckType[5]], deckType[5]);

            debuffTestCount.value += microDiscomfortListCount;
            debuffTestCount.value += (strongDiscomfortListCount * 2);
            debuffTestCount.value += (unacceptableListCount * 3);

            console.log(debuffTestCount.value);

            if (debuffTestCount.value > 0) {
                ElMessage({
                    message: '因携带了负面卡牌通过遭遇战，获得 ' + debuffTestCount.value + ' 个货币',
                    grouping: true,
                    type: 'success',
                });

                gameConfig.money += debuffTestCount.value;
            }
        }

        playerMoney.value = gameConfig.money;
        save(gameConfig);

        mapNextButtonDisabled.value = true;
        setTimeout(() => {
            mapNextButtonDisabled.value = false;
        }, 3000);
    }
};

// 获取隐藏箱事件
export const nextChest = () => {
    const gameConfig = read();
    if (gameConfig.map == "") return;

    if (chestStepNum.value <= chestSteps.value) {
        chestStepNum.value += 1;

        gameConfig.money += 3;
        gameConfig.chestPoint = chestStepNum.value;
        playerMoney.value = gameConfig.money;
        save(gameConfig);

        ElMessage({
            message: '已获取隐藏箱获得 3 货币',
            grouping: true,
            type: 'success',
        });

        chestNextButtonDisabled.value = true;
        setTimeout(() => {
            chestNextButtonDisabled.value = false;
        }, 3000);
    }
};

// 更改货币数量按钮
export const setMoney = () => {

    if (moneyInputRef.value == "") {
        ElMessage({
            message: "请输入货币数量",
            grouping: true,
            type: 'error',
        });
        return;
    }

    const gameConfig = read();
    gameConfig.money = Number(moneyInputRef.value);
    playerMoney.value = Number(gameConfig.money);
    save(gameConfig);

    ElMessage({
        message: "已更改货币数量为 " + moneyInputRef.value,
        grouping: true,
        type: 'success',
    });

    moneyDialogVisible.value = false;
    moneyInputRef.value = "";
};

// 更改抽卡次数按钮
export const setDrawCount = () => {

    if (cardInputRef.value == "") {
        ElMessage({
            message: "请输入抽卡次数",
            grouping: true,
            type: 'error',
        });
        return;
    }

    const gameConfig = read();
    gameConfig.drawCount = Number(cardInputRef.value);
    save(gameConfig);

    ElMessage({
        message: "已更改抽卡数量为 " + cardInputRef.value,
        grouping: true,
        type: 'success',
    });

    cardDialogVisible.value = false;
    cardInputRef.value = "";
};

// 无暇按钮
export const flawlessButton = () => {
    // 添加货币
    const gameConfig = read();
    gameConfig.money += 6;
    playerMoney.value = gameConfig.money;
    save(gameConfig);

    ElMessage({
        message: '已添加 6 货币',
        grouping: true,
        type: 'success',
    });

    flawlessButtonDisabled.value = true;
    setTimeout(() => {
        flawlessButtonDisabled.value = false;
    }, 3000);
};

// 赏金去重
const checkBounty = (bountyList: any, bounty: any) => {
    for (const element of bountyList) {
        if (element == bounty) {
            return false;
        }
    }
    return true;
};

// 设置地图信息
const setMapInfo = () => {
    const gameConfig = read();
    if (gameConfig.map != "") {
        mapName.value = gameConfig.map.name;
        mapSteps.value = gameConfig.map.level;
        mapStepNum.value = gameConfig.levelPoint;
        mapStepWidth.value = (100 / Number(mapSteps.value));

        chestSteps.value = gameConfig.chest;
        chestStepNum.value = gameConfig.chestPoint;
        chestStepWidth.value = (100 / Number(chestSteps.value));
    }
};

// 个人事件
const playerEvent = () => {
    const gameConfig = read();

    const eventNumbers = ref([1, 2, 3, 4]);
    eventNumbers.value = shuffle(eventNumbers.value);

    console.log(eventNumbers.value);
    console.log("----个人事件-----");

    if (eventNumbers.value[0] != 1) {

        const event = lotteryByCount(gameConfig.playerEvent);
        for (let i = 0; i < gameConfig.playerEvent.length; i++) {
            if (event.id == gameConfig.playerEvent[i].id) {
                gameConfig.playerEvent[i].count -= 1;
                break;
            }
        }

        gameConfig.playerEventList.push(event);
        save(gameConfig);

        ElMessage({
            message: "你有新的个人事件",
            grouping: true,
            type: "warning",
        });
    }
};

// 全局事件
const globalEvent = () => {
    const gameConfig = read();

    const globalEventNumbers = ref([1, 2, 3, 4]);
    globalEventNumbers.value = shuffle(globalEventNumbers.value);

    console.log("----全局事件-----");
    console.log(globalEventNumbers.value);

    if (globalEventNumbers.value[0] != 1) {
        const globalEvent = lotteryByCount(gameConfig.globalEvent);
        for (let i = 0; i < gameConfig.globalEvent.length; i++) {
            if (globalEvent.id == gameConfig.globalEvent[i].id) {
                gameConfig.globalEvent[i].count -= 1;
                break;
            }
        }

        gameConfig.globalEventList.push(globalEvent);
        save(gameConfig);

        ElMessage({
            message: "队伍有新的全局事件",
            grouping: true,
            type: "warning",
        });
    }
};

// 初始化
export const initOptions = () => {
    const gameConfig = read();
    maps.value = gameConfig.maps;

    setMapInfo();
};
