import {ref} from "vue";
import {read, save} from "@/assets/ts/utils";
import {lottery, lotteryByCount} from "@/assets/ts/lottery";

export const buttonDisabled = ref();
export const opacityValue = ref();
export const mapList = ref();

// 抽取地图事件
export const rollMap = (mapListRef: HTMLStyleElement | undefined, mapNameRef: HTMLStyleElement | undefined) => {
    buttonDisabled.value = true;
    setMapList();

    if (mapListRef) {
        mapListRef.style.transform = 'translateX(-2507.875rem)';
        mapListRef.style.transition = 'all 10s cubic-bezier(0.1, 0.4, 0.25, 1)';
    }

    setTimeout(function () {
        if (mapNameRef) {
            opacityValue.value = 1;
            mapNameRef.innerText = String(mapList.value[43].name);
        }
    }, 11000);
};

// 设置 地图 信息
const setMapList = () => {
    const gameConfig = read();
    const maps = gameConfig.maps;

    // 数量
    const lotteryCount = 50;

    // 地图列表
    const tempMapList = [];

    // 添加 地图 信息
    for (let i = 0; i < lotteryCount; i++) {
        let map;
        if (i == lotteryCount - 7) {
            map = lotteryByCount(maps);
            map.count -= 1;

            // 数据操作
            gameConfig.map = map;
            gameConfig.mapId = map.id - 1;
            gameConfig.level = map.level;
            gameConfig.levelPoint = 1;
            gameConfig.chest = map.chest;
            gameConfig.chestPoint = 1;
            save(gameConfig);
        } else {
            map = lottery(maps);
        }

        tempMapList.push(map);
    }
    mapList.value = tempMapList;
};

export const initMap = () => {
    buttonDisabled.value = false;
    opacityValue.value = 0;
    mapList.value = null;
};