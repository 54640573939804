import {ElMessage} from 'element-plus'
import axios from "axios";
import {ref} from "vue";
import {deckType} from "@/assets/ts/deck";

export const playerMoney = ref(0);
export const drawCardCount = ref(0);

// 设置游戏默认信息
export const setGameConfig = () => {
    axios.get('./json/GameConfig.json').then(response => {
        if (read() == null || read() == undefined) {
            const gameConfig = response.data;
            save(gameConfig);
            console.log("创建存档成功");
        }
    }).catch(error => {
        console.log(error);
    });
}

// 存档
export const save = (config: string[]) => {
    if (config != null) {
        localStorage.setItem('gameConfig', JSON.stringify(config));
    }
}

// 读档
export const read = () => {
    const config = localStorage.getItem("gameConfig");
    if (config != null) {
        return JSON.parse(config);
    }
}

// 删档
export const deleteSave = () => {
    localStorage.removeItem('gameConfig');
    setGameConfig();
    ElMessage({
        message: '清除成功',
        grouping: true,
        type: 'success',
    })
}

// 删除亚托克斯卡牌
export const deleteAatroxCard = () => {
    const gameConfig = read();
    const deckList = gameConfig.deckList[deckType[4]];
    const newDeckList = [];

    for (let i = 0; i < deckList.length; i++) {
        if (deckList[i].id == "Aatrox") {
            deckList[i] = null;
            break;
        }
    }

    for (const card of deckList) {
        if (card != null) {
            newDeckList.push(card);
        }
    }

    gameConfig.deckList[deckType[4]] = newDeckList;
    save(gameConfig);

    ElMessage({
        message: "已删除 - 亚托克斯 - 卡牌",
        grouping: true,
        type: 'success'
    });
}

// 随机生成
export const shuffle = (array: any) => {
    const res: any = ref([]);
    let random;
    while (array.length > 0) {
        random = Math.floor(Math.random() * array.length);
        res.value.push(array[random]);
        array.splice(random, 1);
    }
    return res.value;
}
