import {ref} from 'vue';
import {read, save, setGameConfig} from '@/assets/ts/utils';
import {ElMessage} from "element-plus";
import router from "@/router";

// 提示框设置
export const gameDialogVisible = ref(true);
export const roleDialogVisible = ref(false);
export const captainDialogVisible = ref(false);

// 角色信息
const role = ref("");
const roleId = ref("1");
export const roleModel = ref("");
export const roleIdModel = ref("");
export const roleNameModel = ref("");

// 设置角色
export const setRole = (position: string, newRole: string) => {
    if (position == "player") {
        role.value = newRole;
        roleDialogVisible.value = true;
    }

    if (position == "captain") {
        role.value = newRole;
        captainDialogVisible.value = true;
    }
};

// 设置角色 ID
export const setRoleId = (newRoleId: string) => {
    roleId.value = newRoleId;
};

// 设置角色信息
export const setRoleInfo = (position: string) => {

    if (role.value == "") {
        ElMessage({
            message: '请选择游玩角色',
            grouping: true,
            type: 'error',
        })
        return;
    }

    if (position == "player" && roleId.value == "1") {
        ElMessage({
            message: '请选择游戏编号',
            grouping: true,
            type: 'error',
        })
        return;
    }

    if (roleNameModel.value == "") {
        ElMessage({
            message: '请输入游戏名称 / 游戏ID',
            grouping: true,
            type: 'error',
        });
        return;
    }

    setGameConfig();

    const gameConfig = read();

    gameConfig.position = position;
    gameConfig.role = role.value;
    gameConfig.roleId = roleId.value;
    gameConfig.roleName = roleNameModel.value;
    save(gameConfig);

    router.replace("gamepanel");
};

// 初始化
export const initHome = () => {
    gameDialogVisible.value = true;
    roleDialogVisible.value = false;
    captainDialogVisible.value = false;
    role.value = "";
    roleId.value = "1";
    roleModel.value = "";
    roleIdModel.value = "";
    roleNameModel.value = "";
};
