import {ref} from "vue";
import {read, save, shuffle} from "@/assets/ts/utils";
import {deckType, deleteCard, getDeckListAll} from "@/assets/ts/deck";
import {ElMessage} from "element-plus";

// 卡牌类型名称
export const cardTypeListName = ["微弱增益", "强大增益", "欧皇增益",
    "微弱不适", "重度不适", "反人类",
    "特殊卡牌", "辅助卡牌"];

// 卡组
export const playerDeckList = ref();
export const deckList = ref();

// 提示框
export const cardDialogVisible = ref(false);
export const randomDialogVisible = ref(false);

export const allDeck = ref();

// 删除卡牌
export const deleteCardButton = (card: any, typeIndex: string | number, index: string | number) => {
    deleteCard(card);
    playerDeckList.value[typeIndex].value.splice(index, 1);
};

// 添加卡牌
export const addCardButton = () => {

    for (let i = 0; i < deckList.value.length; i++) {
        deckList.value[i].value = getDeckListAll(deckType[i]);
    }

    cardDialogVisible.value = true;
};
export const addCard = (card: any, index: string | number) => {
    const gameConfig = read();
    const type = card.type;
    const deck = gameConfig.deck[type];

    for (let i = 0; i < deck.length; i++) {
        if (deck[i].id == card.id) {
            gameConfig.deck[type][i].count = 0;
            card = deck[i];
            gameConfig.deckList[type].push(card);
            save(gameConfig);
            playerDeckList.value[index].value.push(card);
            break;
        }
    }

    ElMessage({
        message: "你已添加 - " + card.cardName + " - 卡牌",
        grouping: true,
        type: 'success',
    });
};

// 打乱卡牌
export const randomCardButton = () => {
    const gameConfig = read();
    const tempDeckList: any[] = [];

    deckType.forEach(item => {

        const tempList = gameConfig.deckList[item];

        tempList.forEach((card: any) => {
            tempDeckList.push(card);
        })
    });

    allDeck.value = tempDeckList;

    if (allDeck.value.length == 0) {
        ElMessage({
            message: "你当前没有卡牌可以打乱",
            grouping: true,
            type: 'success',
        });
        return;
    }

    allDeck.value = shuffle(allDeck.value);
    randomDialogVisible.value = true;
};

// 初始化
export const initDeckList = () => {
    // 读取存档
    const gameConfig = read();
    console.log(gameConfig.deckList);

    // 卡组
    const microGainDeckList = ref(gameConfig.deckList[deckType[0]]);
    const strongGainDeckList = ref(gameConfig.deckList[deckType[1]]);
    const opportunityDeckList = ref(gameConfig.deckList[deckType[2]]);
    const microDiscomfortDeckList = ref(gameConfig.deckList[deckType[3]]);
    const strongDiscomfortDeckList = ref(gameConfig.deckList[deckType[4]]);
    const unacceptableDeckList = ref(gameConfig.deckList[deckType[5]]);
    const technologyDeckList = ref(gameConfig.deckList[deckType[6]]);
    const supportDeckList = ref(gameConfig.deckList[deckType[7]]);
    playerDeckList.value = [microGainDeckList, strongGainDeckList, opportunityDeckList,
        microDiscomfortDeckList, strongDiscomfortDeckList, unacceptableDeckList,
        technologyDeckList, supportDeckList];

    // 卡组添加
    const microGainList: any = ref([]);
    const strongGainList: any = ref([]);
    const opportunityList: any = ref([]);
    const microDiscomfortList: any = ref([]);
    const strongDiscomfortList: any = ref([]);
    const unacceptableList: any = ref([]);
    const technologyList: any = ref([]);
    const supportList: any = ref([]);
    deckList.value = [microGainList, strongGainList, opportunityList,
        microDiscomfortList, strongDiscomfortList, unacceptableList,
        technologyList, supportList];
};
