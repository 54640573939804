import {ref} from "vue";
import {read, save, playerMoney} from "@/assets/ts/utils";
import {ElMessage} from "element-plus";
import {deckType, deleteCard} from "@/assets/ts/deck";
import {lotteryByCount} from "@/assets/ts/lottery";

export const fixedItems = ref();
export const randomItems = ref();
export const backpackItems = ref();
export const backpackImg = ref();
export const refreshMoney = ref();
export const refreshCount = ref();
export const shopClosed = ref();

// 商店价格提高
const profiteer = ref();

// 圣水提示框
export const waterDialogVisible = ref();

// 固定售卖图片
export const fixedImg: any = ref([]);

// 提示框
export const tooltipShow = ref();
export const tooltipLocation = ref();
export const itemName = ref();
export const itemKind = ref();
export const itemRarity = ref();
export const itemDescription = ref();
export const sellMoney = ref();
export const wrapper = ref();
export const header = ref();
export const waterDeckList = ref([]);
export const randomItemsImg: any = ref([]);

export const moveTooltip = (e: any) => {
    // values: e.clientX, e.clientY, e.pageX, e.pageY
    const x = e.pageX + 8;
    const y = e.pageY + 8;

    tooltipLocation.value = 'translate(' + x + 'px, ' + y + 'px)';
};
export const showTooltip = (item: any) => {
    tooltipShow.value = true;
    setToolTips(item);
};
export const hideTooltip = () => {
    tooltipShow.value = false;
};
// 设置提示信息
const setToolTips = (item: any) => {

    switch (item.rarity) {
        case "异域":
            wrapper.value = "#2A271A";
            header.value = "#CFB444";
            break;
        case "传说":
            wrapper.value = "#262727";
            header.value = "#633F60";
            break;
        case "稀有":
            wrapper.value = "#262727";
            header.value = "#5F81AB";
            break;
        case "罕见":
            wrapper.value = "#262727";
            header.value = "#477B4D";
            break;
        case "无":
        default:
            wrapper.value = "#262727";
            header.value = "#C6C0B9";
            break;
    }

    itemName.value = item.itemName;
    itemKind.value = item.kind;
    itemRarity.value = item.rarity;
    itemDescription.value = item.description;
    sellMoney.value = item.sell;

    if (profiteer.value) {
        sellMoney.value = item.sell + 1;
    }
};

// 购买物品
export const buyItem = (item: any, index: number) => {
    switch (item.type) {
        case "water":
            buyWater(item);
            break;
        case "drawCount":
            buyDrawCount(item);
            break;
        case "Weapons":
            buyRandomItem(item, index);
            break;
        case "Armor":
            buyRandomItem(item, index);
            break;
        default:
            break;
    }
}

// 购买圣水
const buyWater = (item: any) => {
    const gameConfig = read();
    const sell = ref(item.sell);

    if (profiteer.value) {
        sell.value += 1;
    }

    if (playerMoney.value < sell.value) {
        ElMessage({
            message: "货币不足无法购买",
            grouping: true,
            type: 'error',
        });
        return;
    }

    const itemName = item.name;
    const cardType = ref("");

    switch (itemName) {
        case "water1":
            cardType.value = deckType[3];
            break;
        case "water2":
            cardType.value = deckType[4];
            break;
        case "water3":
            cardType.value = deckType[5];
            break;
        default:
            break;
    }

    waterDeckList.value = gameConfig.deckList[cardType.value];

    if (waterDeckList.value.length == 0) {
        ElMessage({
            message: "当前没有可以消除的卡牌",
            grouping: true,
            type: 'error',
        });
        return;
    }

    waterDialogVisible.value = true;
};

// 购买抽卡机会
const buyDrawCount = (item: any) => {
    const sell = item.sell;

    if (profiteer.value) {
        sell.value += 1;
    }

    if (playerMoney.value < sell) {
        ElMessage({
            message: "货币不足无法购买",
            grouping: true,
            type: 'error',
        });
        return;
    }

    const gameConfig = read();
    gameConfig.money -= sell;
    gameConfig.drawCount += 1;
    playerMoney.value = gameConfig.money;
    save(gameConfig);

    ElMessage({
        message: "您已增加一次抽卡机会",
        grouping: true,
        type: 'success',
    });
};

// 购买物品
const buyRandomItem = (item: any, index: number) => {
    const gameConfig = read();
    // 货币
    const money = playerMoney.value;
    let sell = item.sell;

    if (profiteer.value) sell += 1;
    console.log(gameConfig.market);
    // 货币不足
    if (money < sell && !gameConfig.market) {
        ElMessage({
            message: '货币不足无法购买',
            grouping: true,
            type: 'error',
        });
        return;
    }

    // 购买成功
    gameConfig.money = money - sell;
    playerMoney.value = gameConfig.money;

    item.count--;

    // 恶魔契约
    if (gameConfig.devilspact != 0) {
        gameConfig.devilspact -= 1;
        gameConfig.drawCount += 1;
    }

    gameConfig.backpack.push(item);
    gameConfig.backpackImg.push(randomItemsImg.value[index]);

    backpackItems.value = gameConfig.backpack;
    backpackImg.value = gameConfig.backpackImg;

    save(gameConfig);

    ElMessage({
        message: '购买 ' + item.itemName + ' 成功',
        grouping: true,
        type: 'success',
    });
};

// 删除卡牌
export const deleteCardItem = (card: any, index: number) => {

    let sell = 0;

    switch (card.type) {
        case deckType[3]:
            sell = 3;
            break;
        case deckType[4]:
            sell = 6;
            break;
        case deckType[5]:
            sell = 12;
            break;
        default:
            break;
    }

    if (profiteer.value) {
        sell += 1;
    }

    if (playerMoney.value < sell) {
        ElMessage({
            message: "货币不足无法购买",
            grouping: true,
            type: 'error',
        });
        return;
    }

    deleteCard(card);
    waterDeckList.value.splice(index, 1);

    const gameConfig = read();
    gameConfig.money -= sell;
    playerMoney.value = gameConfig.money;
    save(gameConfig);
};

// 刷新商店按钮
export const refreshShop = () => {
    const gameConfig = read();
    const refreshMod = gameConfig.refreshCount >= 1 ? "free" : "pay";

    if (refreshMod === "free") {
        refreshCount.value -= 1;
        gameConfig.refreshCount--;
    } else if (refreshMod === "pay") {
        if (playerMoney.value - gameConfig.refreshMoney < 0) {
            ElMessage({
                message: "货币不足",
                grouping: true,
                type: 'error',
            });
            return;
        }

        gameConfig.money -= gameConfig.refreshMoney;
        gameConfig.refreshMoney += 1;

        refreshMoney.value = gameConfig.refreshMoney;
        playerMoney.value = gameConfig.money;
    }
    save(gameConfig);

    refreshShopItem();
};

// 刷新商店
const refreshShopItem = () => {
    const gameConfig = read();
    gameConfig.shop.randomItems[0] = lotteryByCount(gameConfig.shop.weapons.weapons1);
    gameConfig.shop.randomItems[1] = lotteryByCount(gameConfig.shop.weapons.weapons2);
    gameConfig.shop.randomItems[2] = lotteryByCount(gameConfig.shop.weapons.weapons3);
    gameConfig.shop.randomItems[3] = lotteryByCount(gameConfig.shop.weapons.exotic);

    // 检测角色类型
    switch (gameConfig.role) {
        case "titan":
            gameConfig.shop.randomItems[4] = lotteryByCount(gameConfig.shop.armor.titanArmor);
            break;
        case "hunter":
            gameConfig.shop.randomItems[4] = lotteryByCount(gameConfig.shop.armor.hunterArmor);
            break;
        case "warlock":
            gameConfig.shop.randomItems[4] = lotteryByCount(gameConfig.shop.armor.warlockArmor);
            break;
        default:
            break;
    }

    randomItems.value = gameConfig.shop.randomItems;
    save(gameConfig);
    setRandomItemsImg();
}

// 随机商店物品图片
const setRandomItemsImg = () => {
    const gameConfig = read();

    if (gameConfig.shop.randomItems[0].itemName === "空物品") return;
    randomItemsImg.value[0] = require("@/assets/images/shop/weapons1/" + gameConfig.shop.randomItems[0].itemName + ".jpg");
    randomItemsImg.value[1] = require("@/assets/images/shop/weapons2/" + gameConfig.shop.randomItems[1].itemName + ".jpg");
    randomItemsImg.value[2] = require("@/assets/images/shop/weapons3/" + gameConfig.shop.randomItems[2].itemName + ".jpg");
    randomItemsImg.value[3] = require("@/assets/images/shop/exotic/" + gameConfig.shop.randomItems[3].itemName + ".jpg");
    randomItemsImg.value[4] = require("@/assets/images/shop/" + gameConfig.role + "/" + gameConfig.shop.randomItems[4].itemName + ".jpg");
    save(gameConfig);
}

// 开启商店
export const openShop = () => {
    const sell = 12;

    if (playerMoney.value < sell) {
        ElMessage({
            message: "你需要 " + sell + " 货币才能解锁商店",
            grouping: true,
            type: 'error',
        });
        return;
    }

    const gameConfig = read();
    gameConfig.money -= sell;
    playerMoney.value = gameConfig.money;
    save(gameConfig);

    const name = "Stillwater-Prison";
    gameConfig.deckList[deckType[5]].forEach((card: any) => {
        if (card.name == name) {
            deleteCard(card);
        }
    });

    ElMessage({
        message: "你已重新开启商店系统",
        grouping: true,
        type: 'success',
    });
    shopClosed.value = false;
}

// 商店初始化
export const initShop = () => {
    const gameConfig = read();

    fixedItems.value = gameConfig.shop.fixedItems;
    randomItems.value = gameConfig.shop.randomItems;
    backpackItems.value = gameConfig.backpack;
    backpackImg.value = gameConfig.backpackImg;
    refreshMoney.value = gameConfig.refreshMoney;
    refreshCount.value = gameConfig.refreshCount;
    shopClosed.value = false;
    profiteer.value = false;
    waterDialogVisible.value = false;
    fixedImg.value[0] = require("@/assets/images/shop/water.jpg");
    fixedImg.value[1] = require("@/assets/images/shop/water.jpg");
    fixedImg.value[2] = require("@/assets/images/shop/water.jpg");
    fixedImg.value[3] = require("@/assets/images/shop/card.png");
    tooltipShow.value = false;
    tooltipLocation.value = 'translate(0px, 0px)';
    itemName.value = "合成感受器";
    itemKind.value = "臂铠";
    itemRarity.value = "异域";
    itemDescription.value = "这是一个泰坦的臂铠";
    sellMoney.value = 0;
    wrapper.value = "#2A271A";
    header.value = "#CFB444";

    // 商店检测
    gameConfig.deckList[deckType[5]].forEach((card: any) => {
        if (card.name == "Stillwater-Prison") {
            ElMessage({
                message: "您的商店系统已被关闭！",
                type: 'error',
                duration: 0,
                showClose: true,
            });

            shopClosed.value = true;
            return;
        }
    });

    // 价格检测
    gameConfig.deckList[deckType[4]].forEach((card: any) => {
        if (card.name == "Reicher-Playboy") {
            profiteer.value = true;
            ElMessage({
                message: "购买任意物品价格提高 1 货币！",
                grouping: true,
                duration: 0,
                showClose: true,
            });
            return;
        }
    });

    // 恶魔契约检测
    if (gameConfig.devilspact != 0) {
        ElMessage({
            message: "恶魔契约已启用",
            grouping: true,
            duration: 0,
            showClose: true,
        });
    }

    setRandomItemsImg();
    console.log(gameConfig.shop.randomItems);
}