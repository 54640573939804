import {createRouter, createWebHistory, RouteRecordRaw, useRoute} from 'vue-router'
import IndexView from '../views/IndexView.vue'
import HomeView from '../views/HomeView.vue'
import Destiny2View from '../views/Destiny2View.vue';
import GamePlayView from '../views/GameplayView.vue';
import UpdateView from '../views/UpdateView.vue';
import CopyrightView from "../views/CopyrightView.vue";
import GamePanelView from '../views/GamePanelView.vue';
import MapView from '../views/MapView.vue';
import ShopView from '../views/ShopView.vue';
import DeckView from '../views/DeckView.vue';
import DeckListView from '../views/DeckListView.vue';
import BountyView from '../views/BountyView.vue';
import PlayerEventView from '../views/PlayerEventView.vue';
import GlobalEventView from '../views/GlobalEventView.vue';
import OptionsView from '../views/OptionsView.vue';
import {read} from "@/assets/ts/utils";


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/destiny2',
    name: 'destiny2',
    component: Destiny2View
  },
  {
    path: '/gameplay',
    name: 'gameplay',
    component: GamePlayView
  },
  {
    path: '/update',
    name: 'update',
    component: UpdateView
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: CopyrightView
  },
  {
    path: '/gamepanel',
    name: 'gamepanel',
    component: GamePanelView,
    redirect: '/options',
    children: [
      {
        path: '/map',
        name: 'map',
        component: MapView
      },
      {
        path: '/options',
        name: 'options',
        component: OptionsView
      },
      {
        path: '/deck',
        name: 'deck',
        component: DeckView
      },
      {
        path: '/decklist',
        name: 'decklist',
        component: DeckListView
      },
      {
        path: '/bounty',
        name: 'bounty',
        component: BountyView
      },
      {
        path: '/playerevent',
        name: 'playerevent',
        component: PlayerEventView
      },
      {
        path: '/globalevent',
        name: 'globalevent',
        component: GlobalEventView
      },
      {
        path: '/shop',
        name: 'shop',
        component: ShopView
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


