import {playerMoney, read} from "@/assets/ts/utils";
import {ref} from "vue";
import {useRoute} from "vue-router";

export const position = ref("");
export const role = ref("");
export const roleId = ref("");
export const roleName = ref("");

const route: any = ref("");
const routePath = ['/map', '/options', '/deck', '/decklist', '/bounty', '/playerevent', '/globalevent', '/shop'];

// 名片
export const emblemSpecial = ref("");
export const emblemIcon = ref("");

// 导航栏
export const activeIndex = ref(2);

export const setActive = (index: number) => {
    activeIndex.value = index;
}

export const initGamePanel = () => {
    const gameConfig = read();

    route.value = useRoute();
    for (let i = 0; i < routePath.length; i++) {
        const routePathStr = route.value.path;
        if (routePathStr == routePath[i]) {
            activeIndex.value = i + 1;
        }
    }

    position.value = gameConfig.position;
    role.value = gameConfig.role;
    roleId.value = gameConfig.roleId;
    roleName.value = gameConfig.roleName;
    playerMoney.value = gameConfig.money;

    if (roleName.value == "和泉纱雾") {
        emblemSpecial.value = require('@/assets/images/emblem/es-w.jpg');
        emblemIcon.value = require('@/assets/images/emblem/es_icon.png');
    } else if (position.value != "captain") {
        emblemSpecial.value = require('@/assets/images/emblem/' + role.value + '-w.jpg');
        emblemIcon.value = require('@/assets/images/emblem/' + role.value + '_icon.png');
    } else {
        emblemSpecial.value = require('@/assets/images/emblem/' + position.value + '-w.jpg');
        emblemIcon.value = require('@/assets/images/emblem/' + position.value + '_icon.png');
    }
};