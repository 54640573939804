import {ref} from "vue";
import {read, save, shuffle} from "@/assets/ts/utils";
import {ElMessage} from "element-plus";
import {lottery} from "@/assets/ts/lottery";
import {checkDeck, deckType} from "@/assets/ts/deck";

// 全局事件
export const globalEventList = ref();

// 抽卡模态框
export const deckDialogVisible = ref(false);

// 属性
export const eventFlipList: any = ref([]);
export const eventTitle = ref("当前没有事件");
export const playersText = ref("");

export const selfDeckList = ref();
export const cardFlip = ref(Array.from({length: 6}, () => false));

// 接受事件
export const acceptEvent = (playerEvent: any, index: number) => {
    runEvent(playerEvent.name);
    globalEventList.value[index].stage = 'active';
};

// 完成事件
export const finishEvent = (playerEvent: any, index: number) => {
    ElMessage({
        message: "已完成事件",
        grouping: true,
        type: 'success',
    });

    deleteEvent(index);
};

// 删除事件
const deleteEvent = (index: number) => {
    const gameConfig = read();
    globalEventList.value.splice(index, 1);

    const newEventList: any = ref([]);

    globalEventList.value.forEach((item: any) => {
        if (item) {
            newEventList.value.push(item);
        }
    });

    gameConfig.globalEventList = newEventList.value;
    save(gameConfig);

    if (globalEventList.value.length == 0) {
        eventTitle.value = "当前没有事件";
    }
};

// 全局事件处理机制 ↓
const runEvent = (eventName: any) => {
    const gameConfig = read();

    let players = [1, 2, 3, 4, 5, 6];

    do {
        players = shuffle(players);
    }
    while(players[0] == gameConfig.roleId);

    let str = "";

    // 紧急支援
    if (eventName == "MAYDAY") {
        const dungeon = lottery(gameConfig.dungeons);
        str = `玩家 ${players[0]} | ${players[1]} | ${players[2]} 需前往 - ${dungeon.name} -`;
    }

    // 五谷丰登
    if (eventName == "Bumper-Harvest") {
        deckDialogVisible.value = true;
        const deck = [];

        // 微弱增益
        while (deck.length != 6) {
            const card = lottery(gameConfig.deck[deckType[0]]);
            if (checkDeck(deck, card)) {
                deck.push(card);
            }
        }

        selfDeckList.value = deck;
        str = `抽取顺序为 ${players[0]} | ${players[1]} | ${players[2]} ` +
            `| ${players[3]} | ${players[4]} | ${players[5]}`;

        setTimeout(() => {
            cardFlip.value = Array.from({length: 6}, () => true);
        }, 500);
    }

    // 各自为营
    if (eventName == "Split-Up") {
        str = `${players[0]} | ${players[1]} | ${players[2]} 需要做为 1 队，` +
            `${players[3]} | ${players[4]} | ${players[5]} 需要做为 2 队 ` +
            `分别完成此次遭遇战`;
    }

    // 斗地主
    if (eventName == "Dou-Di-Zhu") {
        str = `玩家 ${players[0]} | ${players[1]} | ${players[2]} 需前往 斗地主环节`;
    }

    // 木头人
    if (eventName == "Wood-Man") {
        str = `玩家 ${players[0]} 需要当报数者，剩余玩家当木头人，前往熔炉竞技场进行此活动`;
    }

    // 改变事件状态
    for (let i = 0; i < globalEventList.value.length; i++) {
        if (eventName == globalEventList.value[i].name) {
            gameConfig.globalEventList[i].stage = "active";
            save(gameConfig);
            break;
        }
    }

    if (str != "") {
        ElMessage({
            message: str,
            duration: 0,
            showClose: true,
        });
    }
};

// 初始化
export const initGlobalEvent = () => {
    // 读取存档
    const gameConfig = read();
    globalEventList.value = gameConfig.globalEventList;
    console.log(gameConfig.globalEventList);

    eventFlipList.value = [];

    if (globalEventList.value.length != 0) {
        eventTitle.value = "当前有新事件";
        for (const element of globalEventList.value) {
            eventFlipList.value.push(false);
        }

        setTimeout(() => {
            for (let i = 0; i < eventFlipList.value.length; i++) {
                eventFlipList.value[i] = true;
            }
        }, 100);
    } else {
        eventTitle.value = "当前没有事件";
    }
};